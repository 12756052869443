<template>
  <div class="BuyBox">
    <ConfirmModal
      v-if="showConfirmAddToCart"
      :show-abort="true"
      @close="showConfirmAddToCart = false"
      @click-outside="showConfirmAddToCart = false"
      @confirm="addToCart"
    >
      <div class="p-32 pb-24 type-lg">
        {{ $t('productPage.buybox.confirmBackorderTitle', { date: estimatedDeliveryDate }) }}
      </div>
      <div class="wysiwyg pr-32 pl-24 pb-32">
        <ul>
          <li>
            {{ $t('productPage.buybox.confirmBackorderContentFirst') }}
          </li>
          <li>
            {{ $t('productPage.buybox.confirmBackorderContentSecond') }}
          </li>
        </ul>
      </div>
    </ConfirmModal>
    <MultiBuyDiscount
      :key="activeVariant.partNo"
      v-model="priceListLocked"
      :active-variant="activeVariant"
    />
    <div v-if="!activeVariant.productHasExpired" class="type-sm-medium mb-12 uppercase">
      <div
        v-if="!isQuickBuy"
        :class="{ 'hidden': disabledQuantityBtns,
        }"
      >
        {{ $t('productPage.sizeSelector.quantityLabel') }}
      </div>
      <div v-if="!disabledQuantityBtns" class="flex justify-between items-end mt-4 select-none">
        <button
          class="btn btn--secondary btn--minus !basis-44 !h-44 !w-44 shrink-0"
          :disabled="totalQuantity === subOrAddQuantity"
          @click="totalQuantity = (totalQuantity - subOrAddQuantity)"
        />
        <div
          class="basis-44 p-12 flex items-center justify-center"
        >
          {{ totalQuantity }}
        </div>
        <button
          class="btn btn--secondary btn--plus !basis-44 !h-44 !w-44 shrink-0"
          :disabled="!globalStore.getAllowBackorders && totalQuantity + subOrAddQuantity > canBuyMoreAmount && !activeVariant.isOnDemandProduct"
          @click="totalQuantity = (totalQuantity + subOrAddQuantity)"
        />
        <div class="basis-22" />
        <div
          v-for="bulkOption in bulkOptions"
          :key="'bulk-option-' + bulkOption"
          class="basis-44 h-44 shrink-0"
        >
          <button
            class="btn btn--secondary !basis-44 !h-44 !w-44 shrink-0"
            :disabled="!globalStore.getAllowBackorders && !activeVariant.isOnDemandProduct && canBuyMoreAmount < bulkOption"
            @click="totalQuantity = (totalQuantity = bulkOption)"
          >
            +{{ bulkOption }}
          </button>
        </div>
      </div>
    </div>
    <div v-if="!globalStore.getAllowBackorders && !activeVariant.isOnDemandProduct">
      <div v-if="canBuyMoreAmount === 0 && cartStore.getQuantityFromPartNo(activeVariant.partNo) > 0">
        {{ $t('stock.maxInCart') }}
      </div>
      <div v-else-if="totalQuantity >= canBuyMoreAmount && canBuyMoreAmount > 0" class="type-sm-medium">
        {{ $t('stock.maxCanAdd', { num: canBuyMoreAmount }) }}
      </div>
    </div>
    <div class="flex items-center type-xs my-16">
      <StockStatus :variant="activeVariant" :product-item="productItem" />
    </div>

    <div
      v-if="!globalStore.getAllowBackorders && activeVariant && !activeVariant.canAddToCart && !activeVariant.productHasExpired && userStore.isLoggedIn"
    >
      <div v-if="!stockAlertSuccess" class="relative h-48 mb-12">
        <input
          v-model="outOfStockEmail"
          type="email"
          class="input h-48"
          :placeholder="$t('productPage.stockAlertPlaceholder')"
          @keyup.enter="subscribeToStockUpdates"
        >
      </div>
      <p v-if="stockAlertError" class="text-brightRed text-14 mb-12">
        {{ $t('productPage.stockAlertError') }}
      </p>
      <p v-if="stockAlertSuccess" class="text-lightest bg-success p-4 text-center text-14 mb-12">
        {{ stockAlertSuccessText ?? $t('productPage.stockAlertSuccess') }}
      </p>
    </div>

    <div v-if="activeVariant.productHasExpired">
      <div v-if="relatedList.length" class="type-sm-medium mb-12 uppercase">
        {{ $t('productPage.similarProducts.title') }}

        <ProductRelatedProductsList :list="relatedList" :show-buy-btns="false" />
      </div>
    </div>
    <div v-else class="flex">
      <button
        v-if="!userStore.isLoggedIn"
        class="btn btn--lg flex-1 w-full mb-24 btn--confirm"
        @click.prevent="showLogin"
      >
        {{ $t('priceInfo.loginForPriceInfo') }}
      </button>
      <button
        v-else-if="!globalStore.getAllowBackorders && !activeVariant.canAddToCart"
        class="btn btn--lg flex-1 w-full mb-24 btn--secondary"
        :disabled="!validNotifyMail"
        @click="subscribeToStockUpdates"
      >
        {{ $t('notify.openModalButton') }}
      </button>

      <Tooltip
        v-else-if="userStore.isFinanceUser || userStore.isBlockedCompany"
        :show-on-hover="true"
        class="ml-8 w-full "
        :text="userStore.isBlockedCompany ? $t('company.blocked') : $t('priceInfo.financeUserTooltip')"
      >
        <button
          class="btn btn--lg flex-1 w-full mb-24 btn--confirm"
          :class="{
            'pointer-events-none': true,
          }"
          :disabled="true"
        >
          {{ addToCartLabel }}
          <span
            v-if="totalSum > 0"
            class="ml-8 text-12 normal-case"
          >
            {{ totalSum }} kr
          </span>
        </button>
      </Tooltip>

      <button
        v-else
        class="btn btn--lg flex-1 w-full mb-24 btn--confirm"
        :class="{
          'loading': loading,
          'pointer-events-none': disabledBuyButton,
        }"
        :disabled="disabledBuyButton"
        @click="maybeConfirmAddToCart"
      >
        {{ addToCartLabel }}
        <span
          v-if="totalSum > 0"
          class="ml-8 text-12 normal-case"
        >
          {{ totalSum.toFixed(2) }} kr
        </span>
      </button>

      <button
        v-if="userStore.canFavorizeProducts"
        class="w-48 h-48 px-12 ml-12 shrink-0 border hover:bg-lightest hover:border-darkest transition-all"
        :class="{
          'bg-lighter border-lighter' : !isFavorite,
          'bg-lightest border border-darkest' : isFavorite,
        }"
        @click="addOrDeleteFavorite"
      >
        <img
          :src="showStar ? '/icons/favorite-filled.svg' : '/icons/favorite.svg'"
          class="h-24 w-24"
          :class="{
            'favoriteAnimateIn': loadingToTrue,
          }"
        >
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ProductModel } from '~/models/product';
import { useCartStore } from '~/store/cart';
import { useUserStore } from '~/store/user';
import { useGlobalContentStore } from '~/store/globalContent';
import { useProductStore } from '~/store/product';
import { useUiStore } from '~/store/ui';
import StockStatus from '~/components/product/StockStatus.vue';
import ProductRelatedProductsList from '~/components/product-page/ProductRelatedProductsList.vue';
import ProductVariant, { ProductVariantModel } from '~/models/productVariant';
import useApiFetch from '~/composeables/useApiFetch';
import { NorceRelatedProductTypeCodes } from '~/constants/norceCodes';
import MultiBuyDiscount from '~/components/product-page/MultiBuyDiscount.vue';
import Tooltip from '~/components/body/Tooltip.vue';
import { CampaignItem } from '~/constants/types/multiDiscount';
import useMultiDiscount from '~/composeables/useMultiDiscount';
import ConfirmModal from '~/components/modals/ConfirmModal.vue';
import useStockStatus, { StockLabel } from '~/composeables/useStockStatus';

const userStore = useUserStore();
const uiStore = useUiStore();
const cartStore = useCartStore();
const globalStore = useGlobalContentStore();
const productStore = useProductStore();
const { $t } = useNuxtApp();
const relatedList = ref<ProductModel[]>([]);

interface Props {
  activeVariant: ProductVariantModel & { name: string },
  productItem: ProductModel,
  minimal?: boolean,
  disabled?: boolean
  isQuickBuy?: boolean,
  crossell?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
  minimal: false,
  disabled: false,
  isQuickBuy: false,
  crossell: false,
});

const totalQuantity = ref(props.activeVariant.recommendedQty ?? 1);

const subOrAddQuantity = ref(props.activeVariant.recommendedQty ?? 1);

const { percentageCampaigns, hasCampaign, giftCampaigns } = useMultiDiscount({ activeVariant: props.activeVariant });

const priceListLocked = ref(hasCampaign && !giftCampaigns.value.length);

const userPrice = computed(() => {
  return userStore.getPrice(props.activeVariant.partNo, false);
});

// *** User price vs normal price
const checkIfUserPriceIsLower = () => {
  if (userPrice.value?.status === 'ok') {
    if (userPrice.value.price?.priceBeforeDiscountBeforeVat !== userPrice.value.price?.priceBeforeVat && !giftCampaigns.value.length) {
      priceListLocked.value = false;
    }
  }
};

// User price already loaded:
checkIfUserPriceIsLower();
// Waiting for user price to load:
watch(() => userPrice.value, () => {
  checkIfUserPriceIsLower();
});

const disabledQuantityBtns = computed(() => {
  return (!userStore.isLoggedIn || props.activeVariant.isSoldOut) && !props.activeVariant.isOnDemandProduct && !globalStore.getAllowBackorders;
});

const canBuyMoreAmount = computed(() => {
  return props.activeVariant.stockValue - cartStore.getQuantityFromPartNo(props.activeVariant.partNo);
});

const bulkOptions = computed(() => {
  if (props.activeVariant.recommendedQty) {
    return [props.activeVariant.recommendedQty * 2, props.activeVariant.recommendedQty * 3, props.activeVariant.recommendedQty * 6];
  }

  return [6, 12, 20];
});

const disabledBuyButton = computed(() => {
  if (userStore.isFinanceUser || userStore.isBlockedCompany) {
    return true;
  } else if (props.disabled) {
    return true;
  } else if (props.productItem.productUnavailable) {
    return true;
  } else if (globalStore.getAllowBackorders) {
    return false;
  } else if (props.activeVariant.isInStock || props.activeVariant.isOnDemandProduct || props.activeVariant.hasIncomingDate) {
    return false;
  } else if (canBuyMoreAmount.value === 0 && !props.activeVariant.isOnDemandProduct) {
    return true;
  }

  return true;
});

const addToCartLabel = computed(() => {
  if (globalStore.getAllowBackorders) {
    return $t('productPage.sizeSelector.addToCartBtn');
  } else if (props.activeVariant.isPreOrderProduct) {
    return $t('productPage.sizeSelector.preOrder');
  } else if (props.activeVariant.isTempSoldOut) {
    return $t('productPage.sizeSelector.watchStockBtn');
  } else if (props.activeVariant.isOnDemandProduct || (props.activeVariant && props.activeVariant.canAddToCart) || props.activeVariant.hasIncomingDate) {
    return $t('productPage.sizeSelector.addToCartBtn');
  }
  return $t('productPage.sizeSelector.watchStockBtn');
});

const totalSum = computed((): number => {
  if ((!globalStore.getAllowBackorders && props.activeVariant.canAddToCart) || (globalStore.getAllowBackorders && !props.activeVariant.productHasExpired)) {
    if (userStore.getPrice(props.activeVariant.partNo, props.crossell).status === 'ok') {
      const basePrice = priceListLocked.value
        ? Number(userStore.getPrice(props.activeVariant.partNo, props.crossell).price?.priceBeforeDiscountBeforeVat) ?? 0
        : Number(userStore.getPrice(props.activeVariant.partNo, props.crossell).price?.priceBeforeVat) ?? 0;

      if (percentageCampaigns.value.length > 0) {
        const highestLimitReachedTrue = percentageCampaigns.value
          .filter(item => totalQuantity.value >= item.limit)
          .reduce<CampaignItem | null>((acc, item) => {
            return !acc || item.limit > acc.limit ? item : acc;
          }, null);

        return highestLimitReachedTrue ? totalQuantity.value * basePrice * ((100 - highestLimitReachedTrue.gainedAmount) / 100) : totalQuantity.value * basePrice;
      } else {
        return totalQuantity.value * basePrice;
      }
    }
  }

  return 0;
});

const showLogin = () => {
  uiStore.setShowSigninModal(true, true);
};

const emit = defineEmits<{
  (e: 'changeSize', size: ProductVariant & { name: string }): void;
  (e: 'addToCart'): void;
  (e: 'addingToCart'): void;
}>();

const outOfStockEmail = userStore.isLoggedIn ? ref(userStore.userProfile.email) : ref('');

const { apiPost, lastResponseCode } = useApiFetch();
const loadingStockAlert = ref(false);
const stockAlertSuccess = ref(false);
const stockAlertSuccessText = ref(null);
const stockAlertError = ref(false);
const subscribeToStockUpdates = async() => {
  if (validNotifyMail.value) {
    loadingStockAlert.value = true;
    stockAlertError.value = false;
    stockAlertSuccess.value = false;
    stockAlertSuccessText.value = null;
    const res = await apiPost(`/products/${props.activeVariant.partNo}/stock-alerts`, {
      email: outOfStockEmail.value,
    });
    if (res) {
      stockAlertSuccess.value = true;
      if (lastResponseCode.value === 200) {
        stockAlertSuccessText.value = $t('productPage.stockAlertSuccessAgain');
      }

      setTimeout(() => stockAlertSuccess.value = false, 3000);
    } else {
      stockAlertError.value = true;
    }

    loadingStockAlert.value = false;
  } else {
    console.warn('not valid');
  }
};

const validNotifyMail = computed(() => {
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  return regex.test(outOfStockEmail.value);
});

const loading = ref(false);
const showConfirmAddToCart = ref(false);
const maybeConfirmAddToCart = async() => {
  if (isBackorderProduct.value) {
    showConfirmAddToCart.value = true;
  } else {
    await addToCart();
  }
};
const addToCart = async() => {
  loading.value = true;
  emit('addingToCart');
  if ((!globalStore.getAllowBackorders && (props.activeVariant.canAddToCart || props.activeVariant.hasIncomingDate)) || globalStore.getAllowBackorders && !props.activeVariant.productHasExpired) {
    await cartStore.updateCart(
      props.activeVariant.partNo,
      totalQuantity.value,
      -1,
      true,
      props.activeVariant,
      false,
      props.productItem.objectID,
      priceListLocked.value
    );
    emit('addToCart');
  }

  loading.value = false;
};

const loadingToTrue = ref(false);
const loadingToFalse = ref(false);

const addOrDeleteFavorite = async() => {
  if (isFavorite.value) {
    loadingToFalse.value = true;
    await userStore.updateFavoriteProductQuantity(props.activeVariant.partNo, 0);
    loadingToFalse.value = false;
  } else {
    loadingToTrue.value = true;
    await userStore.saveFavoriteProduct(props.activeVariant.partNo, totalQuantity.value);
    loadingToTrue.value = false;
  }
};

const showStar = computed(() => {
  return (isFavorite.value && !loadingToFalse.value) || loadingToTrue.value;
});

const isFavorite = computed((): boolean => {
  return !!userStore.userProfile.favoriteProducts?.find(favorite => favorite.id === props.activeVariant.partNo);
});

const isBackorderProduct = computed(() => {
  return globalStore.getAllowBackorders && !props.activeVariant.isInStock;
});

watch(
  () => props.activeVariant,
  () => {
    totalQuantity.value = props.activeVariant.recommendedQty ?? 1;
    stockAlertError.value = false;
    stockAlertSuccess.value = false;
    if (props.activeVariant.productHasExpired) {
      setRelatedList();
    }
  }
);

const setRelatedList = (async() => {
  const partNos = props.productItem.relatedProductsByType(NorceRelatedProductTypeCodes.Replacer);
  if (partNos.length > 0) {
    relatedList.value = await productStore.getRelatedProducts(partNos);
  }
});
const estimatedDeliveryDate = computed(() => {
  return props.activeVariant.incomingDate;
});
</script>

<style lang="postcss">

</style>
